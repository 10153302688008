import React from "react"
import {AppRoot, HomePage, Seo} from "components"

function Home(props) {
  return (
    <>
      <Seo />
      <AppRoot backgroundColor="#111728" page={HomePage} {...props} />
    </>
  )
}

export default Home
